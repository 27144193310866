import React, { useMemo } from "react";
import { Box, Grid } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Close from "@material-ui/icons/Close";

import OptionsGroup from "../../components/OptionsGroup";
import {
  employmentBasisOptions,
  employmentStatusOptions,
  employmentTypeOptions,
} from "../../../../commons/loan-application-constants";
import Button from "../../components/Button";
import { useStyles as useSectionStyles } from "../../../../utils/loan-application.styles";

const fields = ["employmentStatus", "employmentBasis", "employmentType"];

const EmploymentStatusStep = ({
  formik,
  index,
  handlePrevStep,
  handleNextStep,
  isEditing,
  handleDeleteEmployment,
}) => {
  const sectionClasses = useSectionStyles();
  const formikErrors = formik.errors?.income?.items?.at?.(index) || {};
  const formikTouched = formik.touched?.income?.items?.at?.(index) || {};
  const formikValues = formik.values?.income?.items?.at?.(index);

  const onNextStep = () => {
    fields.forEach((field) => {
      formik.setFieldTouched(`income.items[${index}].${field}`);
    });

    if (
      fields.some((field) => formik.errors.income?.items?.at(index)?.[field])
    ) {
      return;
    }

    handleNextStep();
  };

  const isUnemployedOrRetired = useMemo(() => {
    return (
      formikValues?.employmentType === "unemployed" ||
      formikValues?.employmentType === "retired"
    );
  }, [formikValues?.employmentType]);

  return (
    <Box display="flex" flexDirection="column" gridRowGap={48}>
      <Grid container direction="column" spacing={8} sm={8} md={7}>
        <OptionsGroup
          title="Employment Status"
          options={employmentStatusOptions}
          onSelect={(value) =>
            formik.setFieldValue(
              `income.items[${index}].employmentStatus`,
              value
            )
          }
          selectedValue={
            formik.values.income?.items?.at(index)?.employmentStatus
          }
          error={
            formikTouched?.employmentStatus &&
            Boolean(formikErrors?.employmentStatus)
          }
          helperText={
            formikTouched?.employmentStatus && formikErrors?.employmentStatus
          }
        />
        {!isUnemployedOrRetired && (
          <OptionsGroup
            title="Employment Basis"
            options={employmentBasisOptions}
            onSelect={(value) =>
              formik.setFieldValue(
                `income.items[${index}].employmentBasis`,
                value
              )
            }
            selectedValue={
              formik.values.income?.items?.at(index)?.employmentBasis
            }
            error={
              formikTouched?.employmentBasis &&
              Boolean(formikErrors?.employmentBasis)
            }
            helperText={
              formikTouched?.employmentBasis && formikErrors?.employmentBasis
            }
          />
        )}
        <OptionsGroup
          title="Employment Type"
          options={employmentTypeOptions}
          onSelect={(value) =>
            formik.setFieldValue(`income.items[${index}].employmentType`, value)
          }
          selectedValue={formik.values.income?.items?.at(index)?.employmentType}
          error={
            formikTouched?.employmentType &&
            Boolean(formikErrors?.employmentType)
          }
          helperText={
            formikTouched?.employmentType && formikErrors?.employmentType
          }
        />
      </Grid>
      {isEditing && (
        <Grid container>
          <Button
            endIcon={<Close />}
            onClick={() => handleDeleteEmployment(index)}
            customColor="danger"
          >
            Delete this Employment
          </Button>
        </Grid>
      )}
      <Grid
        container
        xs={12}
        md={6}
        justifyContent="space-between"
        classes={{ root: sectionClasses.navigationButtonsContainer }}
      >
        {!isEditing && (
          <Button startIcon={<NavigateBeforeIcon />} onClick={handlePrevStep}>
            Previous
          </Button>
        )}
        <Button endIcon={<NavigateNextIcon />} onClick={onNextStep}>
          Next
        </Button>
      </Grid>
    </Box>
  );
};

export default EmploymentStatusStep;
