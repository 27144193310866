import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import cx from "classnames";
import {
  dangerColor,
  useCommonStyles,
  useOptionStyles,
} from "../../../../utils/loan-application.styles";

const useStyles = makeStyles({
  showCount: {
    position: "absolute",
    top: "-16px",
    left: "4px",
  },
  helperTextWrapper: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  helperTextContainer: {
    position: "absolute",
    top: "3px",
    left: "14px",
  },
  helperText: {
    color: dangerColor,
  },
});

const OptionsGroup = ({
  title,
  options,
  selectedValue,
  onSelect,
  classes: customClasses,
  optionSpacing = 2,
  xs = 12,
  spacing = 3,
  multiple = false,
  allowUnSelect = true,
  maximumAllowed,
  showCount = false,
  helperText,
  error,
  disabled = false,
}) => {
  const optionClasses = useOptionStyles();
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const handleSelect = (value) => {
    if (disabled) return;

    if (multiple) {
      if (selectedValue.includes(value)) {
        if (!allowUnSelect) {
          return;
        }
        onSelect(selectedValue.filter((val) => val !== value));
      } else {
        if (maximumAllowed && selectedValue.length >= maximumAllowed) {
          return;
        }
        onSelect([...selectedValue, value]);
      }
      return;
    }
    onSelect(value);
  };

  const generateClasses = (value) => {
    if (multiple) {
      return cx(optionClasses.option, commonClasses.positionRelative, {
        [optionClasses.selected]: selectedValue.includes(value),
        [optionClasses.disabled]: disabled,
      });
    }

    return cx(optionClasses.option, commonClasses.positionRelative, {
      [optionClasses.selected]: selectedValue === value,
      [optionClasses.disabled]: disabled,
    });
  };

  return (
    <Grid
      item
      container
      spacing={spacing}
      classes={{
        root: cx(commonClasses.positionRelative, customClasses),
      }}
      xs={xs}
    >
      {title && (
        <Grid item>
          <Typography
            classes={{
              root: cx(
                commonClasses.fontSize18,
                commonClasses.fontWeightNormal,
                error && classes.helperText
              ),
            }}
          >
            {title}
          </Typography>
        </Grid>
      )}
      <Grid item container spacing={optionSpacing}>
        {options.map((option) => (
          <Grid item key={option.value}>
            <Box
              classes={{ root: generateClasses(option.value) }}
              onClick={() => handleSelect(option.value)}
            >
              {showCount && selectedValue.includes(option.value) && (
                <Typography
                  classes={{
                    root: cx(
                      classes.showCount,
                      commonClasses.fontSize14,
                      commonClasses.fontWeightBold
                    ),
                  }}
                >
                  #{selectedValue.indexOf(option.value) + 1}
                </Typography>
              )}
              {option.label}
            </Box>
          </Grid>
        ))}
      </Grid>
      {error && (
        <Box classes={{ root: classes.helperTextWrapper }}>
          <Box classes={{ root: classes.helperTextContainer }}>
            <Typography
              classes={{
                root: cx(classes.helperText, commonClasses.fontSize12),
              }}
            >
              {helperText}
            </Typography>
          </Box>
        </Box>
      )}
    </Grid>
  );
};

export default OptionsGroup;
