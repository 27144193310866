export const incomeTypeOptions = [
  { value: "payg-self-employed", label: "PAYG/Self Employed" },
  { value: "dividends", label: "Dividends" },
  { value: "family-allowance", label: "Family Allowance" },
  { value: "maintenance", label: "Maintenance" },
  { value: "other", label: "Other" },
];

export const addOptions = [
  { value: "bonus", label: "Bonus" },
  { value: "overtime", label: "Overtime" },
];

export const employmentTypeOptions = [
  { value: "payg", label: "PAYG" },
  { value: "self-employed", label: "Self Employed" },
  { value: "unemployed", label: "Unemployed" },
  { value: "retired", label: "Retired" },
];

export const employmentStatusOptions = [
  { value: "primary", label: "Primary" },
  { value: "secondary", label: "Secondary" },
  { value: "previous", label: "Previous" },
];

export const employmentBasisOptions = [
  { value: "fulltime", label: "Fulltime" },
  { value: "part-time", label: "Part time" },
  { value: "contract", label: "Contract" },
  { value: "temporary", label: "Temporary" },
  { value: "casual", label: "Casual" },
];

export const companyTypeOptions = [
  { value: "public", label: "Public" },
  { value: "private", label: "Private" },
];

export const companyStructureOptions = [
  { value: "sole-trader", label: "Sole Trader" },
  { value: "company", label: "Company" },
  { value: "trust", label: "Trust" },
];

export const expensesType = [
  {
    value: "clothing-and-personal-care",
    label: "Clothing & Personal Care",
    description: "Clothing, footwear, cosmetics and personal care.",
  },
  {
    value: "higher-education-and-vocational-training-expenses",
    label: "Higher education and vocational training expenses",
    description:
      "Fees, accommodation, books and associated costs for higher education (tertiary) and vocational training e.g. university, TAFE, business college, drama, music, dance (excluding HECS/HELP).",
  },
  {
    value: "groceries",
    label: "Groceries",
    description:
      "Typical supermarket shop for groceries including food and toiletries (excluding alcohol & tobacco).",
  },
  {
    value: "medical-and-health-care",
    label: "Medical and health care",
    description:
      "Medical and health costs including doctor, dental, optical and pharmaceutical etc. (excluding health insurance which is categorised under 'Personal Insurance (Life, Health, Sickness and Personal Accident)').",
  },
  {
    value: "recreation-and-entertainment-expenses",
    label: "Recreation and entertainment expenses",
    description:
      "Recreation and entertainment costs including alcohol, tobacco, gambling, restaurants, membership fees, pet care and holidays.",
  },
  {
    value: "phone-internet-pay-tv-and-media-streaming-subscriptions",
    label: "Phone, internet, Pay TV and media streaming subscriptions",
    description:
      "Telephone accounts (home and mobile), internet, pay TV and media streaming subscriptions (such as Netflix and Spotify).",
  },
  {
    value: "transport",
    label: "Transport",
    description:
      "Public transport, motor vehicle running costs including fuel, servicing, registration, parking and tolls (excluding motor vehicle insurance which is categorised under insurance)",
  },
  {
    value: "other-regular-and-recurring-expenses",
    label: "Other Regular and Recurring Expenses",
    description:
      "Any other regular and recurring subscriptions, payments, bills or fees",
  },
  {
    value: "general-insurance",
    label: "General Insurance",
    description:
      "Insurance costs such as personal belongings, travel and ambulance insurance, home and content, building as well as any compulsory insurance of motor vehicles (combined insurance and registration) other than recreation vehicles.",
  },
  {
    value: "personal-insurance",
    label: "Personal Insurance",
    description:
      "Hospital, medical and dental health insurance, sickness and personal accident insurance, life insurance.",
  },
  {
    value: "other-insurance",
    label: "Other Insurance",
    description:
      "Insurance of recreational vehicles such as moto cycle, caravan, trailer, boat and aircraft including combined insurance and registration.",
  },
];

export const propertyExpensesType = [
  {
    value: "investment-property-costs",
    label: "Investment Property Costs",
    description:
      "All costs associated with an 'Investment Property' including building/contents insurance, rates, taxes, levies, body corporate, strata fees, repairs, maintenance.",
  },
  {
    value: "secondary-residence-and-holiday-home-costs",
    label: "Secondary Residence & Holiday Home Costs",
    description:
      "Costs associated with any secondary residences, either rented or owned for non-investment purposes, such as a holiday property that is not rented to generate income, or a property that family members (parents or children) are allowed to live in rent-free. Includes building/contents insurance, rates, taxes, levies, body corporate, strata fees, repairs, maintenance.",
  },
  {
    value: "board",
    label: "Board",
    description:
      "Ongoing Board commitments that will continue to be paid after settlement.",
  },
  {
    value: "rent",
    label: "Rent",
    description:
      "Ongoing rent commitments that will continue to be paid after settlement.",
  },
];

export const dependantExpensesType = [
  {
    value: "childcare",
    label: "Childcare",
    description: "Childcare including nannies.",
  },
  {
    value: "child-and-spouse-maintenance",
    label: "Child & Spouse Maintenance",
    description: "Child and/or spousal maintenance payments.",
  },
  {
    value: "public-school-education",
    label: "Public School Education",
    description:
      "Education fees, books, uniforms and associated costs for public schooling (preschool, primary or secondary).",
  },
  {
    value: "private-or-non-government-schooling",
    label: "Private or non-government schooling",
    description:
      "Tuition fees, school fees, sports fees, books, uniforms and associated costs for private schooling, including independent schools (Catholic or non-Catholic), private tuition and compulsory age kindergarten/pre-primary/prep/reception.",
  },
];

export const assetsOptions = [
  { value: "property", label: "Property", shortTerm: "Property" },
  { value: "home-contents", label: "Home Contents", shortTerm: "Contents" },
  { value: "motor-vehicle", label: "Motor Vehicle", shortTerm: "Vehicle" },
  { value: "saving", label: "Savings", shortTerm: "Savings" },
  { value: "super", label: "Super", shortTerm: "Super" },
  { value: "shares", label: "Shares", shortTerm: "Shares" },
  { value: "boat", label: "Boat", shortTerm: "Boat" },
  { value: "business-equity", label: "Business Equity", shortTerm: "BE" },
  { value: "cash-management", label: "Cash Management", shortTerm: "CM" },
  { value: "charge-over-cash", label: "Charge Over Cash", shortTerm: "COC" },
  { value: "cheque-account", label: "Cheque Account", shortTerm: "CA" },
  { value: "debenture-charge", label: "Debenture Charge", shortTerm: "DC" },
  { value: "gifts", label: "Gifts", shortTerm: "Gifts" },
  { value: "guarantee", label: "Guarantee", shortTerm: "Guarantee" },
  { value: "investment-savings", label: "Investment Savings", shortTerm: "IS" },
  { value: "life-insurance", label: "Life Insurance", shortTerm: "LI" },
  { value: "managed-funds", label: "Managed Funds", shortTerm: "MF" },
  { value: "term-deposit", label: "Term Deposit", shortTerm: "TD" },
  { value: "other", label: "Other", shortTerm: "Other" },
];

export const propertyTypeOptions = [
  { value: "owner-occupier", label: "Owner Occupier" },
  { value: "investment", label: "Investment" },
];

export const moneyTypeOptions = [
  { value: "monthly", label: "Monthly" },
  { value: "weekly", label: "Weekly" },
];

export const yesNoOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

export const motorVehicleOptions = [
  { value: "bike", label: "Bike" },
  { value: "small", label: "Small" },
  { value: "medium", label: "Medium" },
  { value: "large", label: "Large" },
  { value: "luxury", label: "Luxury" },
  { value: "4wd", label: "4WD" },
];

export const debtOptions = [
  {
    value: "buy-now-pay-later",
    label: "Buy Now Pay Later",
    shortTerm: "BNPL",
  },
  { value: "car-loan", label: "Car Loan", shortTerm: "Car Loan" },
  { value: "credit-card", label: "Credit Card", shortTerm: "CC" },
  { value: "hecs", label: "Hecs", shortTerm: "Hecs" },
  { value: "lease", label: "Lease", shortTerm: "Lease" },
  { value: "line-of-credit", label: "Line of Credit", shortTerm: "LoC" },
  { value: "outstanding-tax", label: "Outstanding Tax", shortTerm: "OT" },
  { value: "overdraft", label: "Overdraft", shortTerm: "Overdraft" },
  { value: "personal-loan", label: "Personal Loan", shortTerm: "PL" },
  { value: "commercial-bill", label: "Commercial Bill", shortTerm: "CB" },
  { value: "hire-purchase", label: "Hire Purchase", shortTerm: "HP" },
  { value: "loan-as-guarantor", label: "Loan as Guarantor", shortTerm: "LaS" },
  { value: "store-card", label: "Store Card", shortTerm: "SC" },
  { value: "term-loan", label: "Term Loan", shortTerm: "TL" },
  { value: "other", label: "Other", shortTerm: "Other" },
];

export const titleOptions = [
  { value: "Dr", label: "Dr" },
  { value: "Miss", label: "Miss" },
  { value: "Mrs", label: "Mrs" },
  { value: "Mr", label: "Mr" },
  { value: "Prof", label: "Prof" },
  { value: "Rev", label: "Rev" },
];

export const genderOptions = [
  { value: "M", label: "Male" },
  { value: "F", label: "Female" },
  { value: "PS", label: "Prefer Not To Say" },
];

export const maritalStatusOptions = [
  { value: "Single", label: "Single" },
  { value: "Married", label: "Married" },
  { value: "DeFacto", label: "De Facto" },
  { value: "Separated", label: "Separated" },
  { value: "Divorced", label: "Divorced" },
  { value: "Widowed", label: "Widowed" },
];

export const loanPurposeOptions = [
  { value: "buy-a-house", label: "Buy a home or move" },
  { value: "refinance-home-loan", label: "Refinance my home loan" },
  { value: "buy-an-investment", label: "Buy an investment property" },
];

export const loanFeaturesAcknowledgementOptions = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
  { value: "i-would-like-to-discuss", label: "I would like to discuss" },
];

export const loanFeatureOptions = [
  { value: "fixed-rate", label: "Fixed Rate" },
  { value: "standard-variable", label: "Variable Rate" },
  { value: "split-loan", label: "Split Loan" },
  // values below is from Connective product search
  { value: "Interest Only", label: "Interest Only" },
  { value: "Redraw", label: "Redraw Facility" },
  { value: "Offset", label: "100% Offset" },
];

export const loanPriorityOptions = [
  { value: "maximize-borrow-amount", label: "to borrow the most amount" },
  { value: "cheapest-interest-rate", label: "Cheapest interest rate" },
  { value: "the-best-features", label: "The best features" },
  { value: "major-lender", label: "Major lender" },
  { value: "small-lender", label: "Small lender" },
  { value: "regional-lender", label: "Regional lender" },
  { value: "non-bank-lender", label: "Non-bank lender" },
  { value: "branch-network", label: "Branch network" },
  { value: "good-customer-service", label: "Good customer service" },
  {
    value: "environmentally-friendly-lender",
    label: "Environmentally friendly lender",
  },
];

export const allOptions = [
  ...incomeTypeOptions,
  ...addOptions,
  ...employmentTypeOptions,
  ...employmentStatusOptions,
  ...employmentBasisOptions,
  ...companyTypeOptions,
  ...companyStructureOptions,
  ...expensesType,
  ...propertyExpensesType,
  ...dependantExpensesType,
  ...assetsOptions,
  ...propertyTypeOptions,
  ...moneyTypeOptions,
  ...yesNoOptions,
  ...motorVehicleOptions,
  ...debtOptions,
  ...titleOptions,
  ...genderOptions,
  ...maritalStatusOptions,
  ...loanPurposeOptions,
  ...loanFeaturesAcknowledgementOptions,
  ...loanFeatureOptions,
  ...loanPriorityOptions,
];
