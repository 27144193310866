import React from "react";
import {
  CircularProgress,
  makeStyles,
  Button as MuiButton,
} from "@material-ui/core";
import cx from "classnames";

import {
  dangerColor,
  textColor,
  textColorGrey,
} from "../../../../utils/loan-application.styles";
import { primaryColor } from "../../../../scss/colors.scss";

const useStyles = makeStyles((theme) => ({
  button: {
    padding: "20px 25px",
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "20px",
    textTransform: "none",
    color: textColor,
    width: "fit-content",
    borderColor: primaryColor,
    [theme.breakpoints.down("xs")]: {
      padding: "12px 16px",
    },
  },
  colorPrimary: {
    color: primaryColor,
  },
  hidden: {
    visibility: "hidden",
  },
  buttonDanger: {
    borderColor: dangerColor,
    "&:hover": {
      borderColor: dangerColor,
    },
  },
  colorDanger: {
    color: dangerColor,
  },
  colorGrey: {
    color: textColorGrey,
  },
  loadingIndicator: {
    marginRight: "10px",
  },
}));

const Button = ({
  children,
  endIcon,
  startIcon,
  onClick,
  hidden,
  disabled = false,
  variant = "outlined",
  classes: customClasses,
  type = "button",
  customColor,
  loading,
}) => {
  const classes = useStyles();
  const generateIconClass = () => {
    if (disabled) {
      return classes.colorGrey;
    }
    if (customColor === "danger") {
      return classes.colorDanger;
    }
    return classes.colorPrimary;
  };
  return (
    <MuiButton
      variant={variant}
      endIcon={endIcon}
      startIcon={startIcon}
      disabled={disabled || loading}
      type={type}
      classes={{
        root: cx(
          classes.button,
          hidden && classes.hidden,
          customColor === "danger" && classes.buttonDanger,
          customClasses
        ),
        endIcon: generateIconClass(),
        startIcon: generateIconClass(),
      }}
      onClick={onClick}
      loading={loading}
    >
      {loading && (
        <CircularProgress
          size={14}
          classes={{ root: classes.loadingIndicator }}
        />
      )}
      {children}
    </MuiButton>
  );
};

export default Button;
